import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import React, { FunctionComponent, ReactElement, SVGProps } from 'react';
import { FlattenInterpolation, FlattenSimpleInterpolation, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components/macro';
import { ITheme } from 'theme';

export type Sizes = 'lg' | 'md' | 'sm' | 'xs';
export type ButtonTypes =
  | 'danger'
  | 'default'
  | 'primary'
  | 'poor'
  | 'link'
  | 'secondary'
  | 'orange'
  | 'blue'
  | 'blueFill'
  | 'transparent';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement;
  styleType?: ButtonTypes;
  size?: Sizes;
  theme?: ITheme;
  padding?: string;
  as?: any;
  to?: string;
  href?: string;
}

const Button: FunctionComponent<IButton> & {
  PlusIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
} = ({ icon, children, ...rest }) => (
  <Component icon={icon} {...rest}>
    {icon}
    <span className="text">{children}</span>
  </Component>
);

Button.defaultProps = {
  size: 'md',
  styleType: 'default',
  type: 'button',
};

Button.PlusIcon = PlusIcon;

const sizesStyles: { [index in Sizes]: FlattenSimpleInterpolation } = {
  lg: css`
    font-size: 18px;
    padding: 16.6px 26.5px 15.4px 25.5px;
  `,
  md: css`
    font-size: 16px;
    padding: 13px 23.5px;
  `,
  sm: css`
    font-size: 14px;
    padding: 8px 14.5px;
  `,
  xs: css`
    font-size: 12px;
    padding: 5px 10px;
  `,
};

const typeStyles: { [index: string]: FlattenInterpolation<ThemeProps<ITheme>> } = {
  danger: css`
    border: none;
    ${({ theme }) => `
      background-color: ${theme.colors.error};
      color: ${theme.colors.snow};
      &:hover {
        background-color: ${theme.colors.errorHover};
      }
      &:disabled {
        background-color: ${theme.colors.disabled};
      }
    `}
  `,
  default: css`
    border: none;
    ${({ theme }) => `
      background-color: ${theme.colors.accentNormal};
      color: ${theme.colors.snow};
      &:hover {
        background-color: ${theme.colors.accentHover};
      }
      &:active {
        color: ${theme.colors.snow};
        background-color: ${theme.colors.accentNormal};
      }
      &:disabled {
        background-color: ${theme.colors.disabled};
      }
    `}
  `,
  link: css`
    ${({ theme }) => `
      border: none;
      outline: none;
      font-weight: 500;
      color: ${theme.colors.accentNormal};
      background-color: transparent;
      &:hover {
        color: ${theme.colors.accentHover};
      }
      &:disabled {
        color: ${theme.colors.disabled};
      }
    `}
  `,
  orange: css`
    ${({ theme }) => `
      border: none;
      background-color: ${theme.colors.warning};
      color: ${theme.colors.snow};
      ${theme.fillSvg(theme.colors.show)};
      &:hover {
        background-color: ${theme.colors.warningHover};
      }
      &:disabled {
        color: ${theme.colors.disabled};
        background-color: ${theme.colors.snow};
        ${theme.fillSvg(theme.colors.disabled)};
      }
    `}
  `,
  poor: css`
    ${({ theme }) => `
      border: none;
      outline: none;
      color: ${theme.colors.noticeGrey};
      background-color: ${theme.colors.snow};
      &:hover {
        color: ${theme.colors.dark};
      }
      &:disabled {
        color: ${theme.colors.disabled};
      }
    `}
  `,
  primary: css`
    ${({ theme }) => `
      border: 2px solid ${theme.colors.accentNormal};
      background-color: ${theme.colors.snow};
      color: ${theme.colors.accentNormal};
      ${theme.fillSvg(theme.colors.accentNormal)}
      &:hover {
        border-color: ${theme.colors.lightAccent};
        background-color: ${theme.colors.lightAccent};
      }
      &:active {
        color: ${theme.colors.snow};
        background-color: ${theme.colors.accentNormal};
        ${theme.fillSvg(theme.colors.snow)}
      }
      &:disabled {
        color: ${theme.colors.disabled};
        background-color: ${theme.colors.snow};
        border-color: ${theme.colors.disabled};
        ${theme.fillSvg(theme.colors.disabled)}
      }
    `}
  `,
  secondary: css`
    ${({ theme }) => `
      border: 2px solid ${theme.colors.lightGrey};
      background-color: ${theme.colors.snow};
      color: ${theme.colors.darkGrey};
      ${theme.fillSvg(theme.colors.lightGrey)};
      &:hover {
        border-color: ${theme.colors.lightGrey};
        background-color: ${theme.colors.lightGrey};
      }
      &:active {
        color: ${theme.colors.snow};
        border-color: ${theme.colors.inputText};
        background-color: ${theme.colors.inputText};
        ${theme.fillSvg(theme.colors.snow)};
      }
      &:disabled {
        color: ${theme.colors.disabled};
        background-color: ${theme.colors.snow};
        border-color: ${theme.colors.disabled};
        ${theme.fillSvg(theme.colors.disabled)};
      }
    `}
  `,
  transparent: css`
    ${({ theme }) => `
      border: 2px solid ${theme.colors.accentNormal};
      color: ${theme.colors.accentNormal};
      background-color: transparent;
      &:hover {
        color: ${theme.colors.accentHover};
      }
      &:disabled {
        color: ${theme.colors.disabled}
      }
    `}
  `,
  blue: css`
    ${({ theme }) => `
      border: 2px solid ${theme.colors.darkBlue};
      background-color: ${theme.colors.snow};
      color: ${theme.colors.darkBlue};
      ${theme.fillSvg(theme.colors.darkBlue)}
      &:hover {
        background-color: ${theme.colors.lightBlue};
      }
    `}
  `,
  blueFill: css`
    ${({ theme }) => `
      border: 2px solid ${theme.colors.darkBlue};
      background-color: ${theme.colors.darkBlue};
      color: ${theme.colors.white};
      ${theme.fillSvg(theme.colors.darkBlue)};
      &:hover { opacity: 0.8 }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    `}
  `,
};

const Component = styled('button').withConfig({
  shouldForwardProp: (prop) => !['styleType', 'icon', 'size', 'padding'].includes(prop),
})<{
  size?: Sizes;
  icon?: ReactElement;
  styleType?: string;
  padding?: string;
}>`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  a.button {
    text-decoration: none;
  }
  align-items: center;
  max-width: 100%;
  border-radius: ${(props) => props.theme.borderRadius};
  position: relative;
  box-sizing: border-box;
  line-height: 24px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  ${(props) => typeStyles[props.styleType!]}
  ${(props) =>
    Boolean(props.icon)
      ? css`
          line-height: 20px;
          font-size: 14px;
          padding: 10px 16px 10px 10px;
          .text {
            padding-left: 10px;
            padding-right: 10px;
          }
        `
      : sizesStyles[props.size!]}
  ${(props) => (props.padding ? `padding:${props.padding}` : '')};
`;

export default Button;
